import { Title } from '@solidjs/meta';
import { createAsync, useAction, useLocation, useNavigate, useSearchParams, useSubmission } from '@solidjs/router';
import { cachedGet, clientAction } from '@troon/api-client';
import {
	ActivityIndicator,
	Button,
	Errors,
	FieldDescription,
	Form,
	Heading,
	HiddenFields,
	Input,
	Label,
	MessageBar,
	Picture,
	TextField,
	TextLink,
} from '@troon/ui';
import { createEffect, createSignal, Match, Show, Suspense, Switch } from 'solid-js';
import { IconLogo } from '@troon/icons/logo';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { useUser } from '../../../providers/root';

export default function Join() {
	const [params] = useSearchParams<{ inviteId?: string; email?: string }>();
	const [showSignup, setShowSignup] = createSignal(false);
	const user = useUser();
	const acceptSubmission = useSubmission(acceptAction);
	const navigate = useNavigate();

	const invite = createAsync(() =>
		getInvite({
			path: { inviteId: params.inviteId ?? '' },
			query: { email: (params.email ?? '').replace(/[ ]/g, '+') },
		}),
	);

	createEffect(() => {
		if (acceptSubmission.result?.success) {
			navigate(`/facility/${invite()?.facility.slug}`);
		}
	});

	return (
		<>
			<Title>Join | Troon Operator</Title>
			<IconLogo title="Troon" class="w-32" />
			<Suspense fallback={<ActivityIndicator />}>
				<Show when={invite()}>
					<span class="size-20 shrink-0 overflow-hidden rounded-md border border-neutral p-2">
						<Show when={invite()?.facility.logo}>
							{(logo) => (
								<Picture
									src={logo()}
									alt=""
									height={100}
									width={100}
									sizes="200px"
									crop="center"
									mode="contain"
									class="w-full"
								/>
							)}
						</Show>
					</span>
					<Show
						when={!showSignup()}
						fallback={
							<Signup
								email={params.email!}
								facilityId={invite()?.facility.id ?? ''}
								inviter={invite()?.inviter.firstName ?? ''}
								inviteId={params.inviteId!}
							/>
						}
					>
						<Heading as="h1" size="h3" class="text-center">
							You’ve been invited to <span class="text-nowrap">Troon Operator</span>
						</Heading>

						<p class="text-center">
							<b>{invite()?.inviter.firstName}</b> has invited you to join the <b>{invite()?.facility.name}</b> team on
							Troon Operator.
						</p>

						<Show
							when={!user()}
							fallback={
								<Form action={acceptAction}>
									<HiddenFields data={{ inviteId: params.inviteId! }} />
									<Button type="submit" class="w-full">
										Join Team <IconArrowRightMd />
									</Button>
								</Form>
							}
						>
							<Button
								class="w-full"
								onClick={() => {
									setShowSignup(true);
								}}
							>
								Join Team <IconArrowRightMd />
							</Button>
						</Show>
					</Show>
				</Show>
			</Suspense>
		</>
	);
}

const getInvite = cachedGet('/v0/operator/auth/invitations/{inviteId}');

function Signup(props: { email: string; facilityId: string; inviteId: string; inviter: string }) {
	const joinSubmission = useSubmission(joinAction);
	const accept = useAction(acceptAction);
	const acceptSubmission = useSubmission(acceptAction);
	const location = useLocation();

	createEffect(() => {
		if (joinSubmission.result) {
			joinSubmission.clear();
			const data = new FormData();
			data.set('inviteId', props.inviteId);
			accept(data);
		}
	});

	return (
		<Switch>
			<Match when={acceptSubmission.pending}>
				<ActivityIndicator>Joining…</ActivityIndicator>
			</Match>
			<Match when={acceptSubmission.error}>
				{(errors) => <MessageBar appearance="danger">{errors().toString()}</MessageBar>}
			</Match>
			<Match when>
				<Form action={joinAction}>
					<TextField name="email">
						<Label>Email address</Label>
						<Input readonly value={props.email.replace(/[ ]/g, '+')} />
						<FieldDescription>
							This email address is tied to your invitation. To use a different email address, request a new invitation
							from {props.inviter}.
						</FieldDescription>
					</TextField>

					<div class="flex w-full flex-wrap justify-stretch gap-8">
						<TextField name="firstName" class="shrink grow">
							<Label>First name</Label>
							<Input autocomplete="given-name" />
						</TextField>
						<TextField name="lastName" class="shrink grow">
							<Label>Last name</Label>
							<Input autocomplete="family-name" />
						</TextField>
					</div>

					<TextField name="password">
						<Label>Password</Label>
						<Input type="password" />
					</TextField>

					<Errors />

					<Button type="submit">Join now</Button>
					<p class="text-sm">
						Already have an account?{' '}
						<TextLink href={`/auth/login?redirect=${location.pathname}?${location.search}`}>Log in</TextLink> to accept
						this invitation.
					</p>
				</Form>
			</Match>
		</Switch>
	);
}

const joinAction = clientAction('POST', '/v0/operator/auth/signup');

const acceptAction = clientAction(
	'POST',
	'/v0/operator/auth/invitations/{inviteId}',
	(data) => ({
		params: { path: { inviteId: data.get('inviteId') as string } },
	}),
	{ revalidate: ['/v0/operator/auth/me', '/v0/operator/facilities'] },
);
